import Image from 'next/image';
import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';

const HistoryTable = (props) => {
    return (
        <div className={`${styles.history_scroll_table} ResponsiveTable`}>
            <table className="CustomizeTable">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Date & Time</th>
                        <th>Placed Bid</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.data.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div
                                        className={`${styles.history_user_separate}`}
                                    >
                                        <div
                                            className={`${styles.history_user_img}`}
                                        >
                                            <Image
                                                alt="Image"
                                                src={data?.profile_photo}
                                                height="40px"
                                                width="40px"
                                                objectFit="cover"
                                                objectPosition="center"
                                            />
                                        </div>
                                        <div
                                            className={`${styles.history_user_details}`}
                                        >
                                            <h2
                                                className={`${styles.history_user_name}`}
                                            >
                                                {data?.user?.username
                                                    ? data?.user?.username
                                                    : data?.user
                                                          ?.public_address}
                                            </h2>
                                            <p
                                                className={`${styles.history_user_email}`}
                                            >
                                                {data?.user?.email
                                                    ? data?.user?.email
                                                    : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {data?.created_date
                                        ? moment(data?.created_date).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : 'N/A'}
                                </td>
                                <td>
                                    {data?.bid}
                                    <div className="gems_icon_img">
                                        <Image
                                            src="/assets/gemssimple.svg"
                                            alt="Logo"
                                            layout="fill"
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryTable;
