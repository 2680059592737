import React from 'react';
import { Modal } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import Input from '../../../FormsElements/Input';
import SelectNFTCard from '../SelectNFTCard';
import styles from './styles.module.scss';
import Button from '../../../../Components/Button';
const SelectNFTModal = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    className={`${styles.nomodalborder}`}
                ></Modal.Header>
                <Modal.Body>
                    <div className={`${styles.selectnft_box}`}>
                        <div className={`${styles.selectnft_heading_separate}`}>
                            <h2 className={`${styles.selectnft_title}`}>
                                Select the NFT for Sponsored Spotlight
                            </h2>
                            <div className={`${styles.selectsearch_textbox}`}>
                                <Input
                                    type={'text'}
                                    placeHolder={`Search`}
                                    id="search"
                                    className={`${styles.selectsearch_inputbox}`}
                                    onChange={(event) => {
                                        props?.onSelectSearchChange(
                                            event.target.value
                                        );
                                    }}
                                />
                                <div
                                    className={`${styles.selectsearch_gems_input_img}`}
                                >
                                    <SVG src="/assets/vectors/search.svg" />
                                </div>
                            </div>
                        </div>
                        <SelectNFTCard
                            setNFTasSelected={props?.setNFTasSelected}
                            data={props?.data}
                        />
                        {props?.isNext === 1 && (
                            <div className={`align-center`}>
                                <Button
                                    varient={'primary'}
                                    onClick={() => props.loadMoreSelectNFT()}
                                >
                                    Load More
                                </Button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${styles.nomodalborder}`}>
                    {/* &nbsp; */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SelectNFTModal;
