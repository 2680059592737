import React from 'react'
import styles from "../resets.module.scss"

const Group = ({ children, className, ...props }) => {
    return (
        <div className={`${styles.group} ${className}`} style={props.style}>
            {children}
        </div>
    )
}

export default Group
