import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../../Components/Button';
import Container from '../../../Components/Container';
import Section from '../../../Components/Section';
import MainTitle from '../../Section/Title/Main';
import { useRouter } from 'next/router';
import UseUtil from '../../../Contexts/Util/useUtil';
import UseAuth from '../../../Contexts/Auth/useAuth';
import SponsorSpotLightCard from '../SponsorSpotLightCard';
import { getNFTForSelection } from '../../../lib/NFT';
import {
  updateBidMasterStatus,
  updateAndGetNewBidRecords,
  getCurrentBid,
  bidHistory,
  setAsSpotlightAPICall,
} from '../../../lib/User';
import styles from './styles.module.scss';
import socketIOClient from 'socket.io-client';
import UseUser from '../../../Contexts/User/useUser';
import ViewHistoryModal from '../SpotLightCard/ViewHistoryModal';
import SelectNFTModal from '../SpotLightCard/SelectNFTModal';
import { useWeb3React } from '@web3-react/core';
import SwitchNetwork from '../../../Hooks/SwitchNetwork';
const ENDPOINT = process.env.NEXT_PUBLIC_API_URL;
import moment from 'moment';
import Swal from 'sweetalert2';
import { getProfile } from '../../../lib/User';
import { createBid } from '../../../lib/User';
import UserProfileLoader from '../../../Components/Loader/userProfileLoader'
import { Col, Row } from 'react-bootstrap';

const SponsorSpotLightSection = (props) => {
  const { userState, updateUserProfile } = UseUser();
  const { authState } = UseAuth();
  const context = useWeb3React();
  const { library, account, chainId } = context;
  const {
    showConnectWalletPopup,
    hideTransactionPopup,
    showTransactionPopup,
    showPurchaseNftPopup,
  } = UseUtil();
  const [socketConnection, setSocketConnection] = useState(null);
  const [timeString, setTimerString] = useState('');
  const [lastPlacedBid, setLastPlacedBid] = useState(null);
  const [lastPlacedBidNft, setLastPlacedBidNft] = useState(null);
  const [lastPlacedBidNftImg, setLastPlacedBidNftImg] = useState();
  const [isBidCompleted, setIsBidCompleted] = useState(false);
  const [gems, setGems] = useState(0);
  const router = useRouter();
  const leftArrow = React.useRef(null);
  const rightArrow = React.useRef(null);
  const [records, setRecords] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [nftSelectList, setNFTSelectList] = useState([]);
  const [nftIsNex, setNFTisNext] = useState(2);
  const [selectNFTModal, setSelectNFTModal] = useState(false);
  const [selectHistoryModal, setSelectHistoryModal] = useState(false);
  const [bidMasterID, setBidMasterID] = useState(null);
  const [bidData, setBidData] = useState();
  const [showCard, setShowCard] = useState(true);
  const [bidArr, setBidArr] = useState();
  const [selectedNFT, setSelectedNFT] = useState();
  const [selectedNftId, setSelectedNftId] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState();
  const [lastPlacedBidId, setLastPlacedBidId] = useState();

  const [selectFilter, setSelectFilter] = useState({
    page_no: 0,
    search: '',
    userId: null,
    is_new: 2,
  });
  useEffect(() => {
    getCurrentBidDataFunction();
  }, []);
  const getCurrentBidDataFunction = async () => {
    let condition = {
      page_type: 1,
      is_main_page: 1,
    };
    const response = await getCurrentBid(condition);
    if (response?.status === 200) {
      setRecords(response.data.data.records);
      setLastPlacedBid(response.data.data.last_bid_data);
      setLastPlacedBidNft(response.data.data.last_bid_data_selected_nft);
      setLastPlacedBidNftImg(response?.data?.data?.last_bid_data_selected_nft_object)
      setLastPlacedBidId(response.data.data.last_bid_data_bid_id)
    }
  };
  const redirectBidPage = async () => {
    if (
      props?.page_type &&
      props?.page_type !== '' &&
      parseInt(props?.page_type) === 2
    ) {
      router.push('/bid-explore');
    } else {
      router.push('/bid');
    }
  };
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    setSocketConnection(socket);
    socket.on('new_home_page_data', (data) => {
      setRecords(data.records);
      // setLastPlacedBid(data.last_bid_data);
      // setLastPlacedBidNft(data.last_bid_data_selected_nft);
    });
  }, []);
  useEffect(() => {
    if (userState?.userProfileData?.gems) {
      setGems(userState?.userProfileData?.gems);
    }
  }, [userState]);
  useEffect(() => {
    if (records && records.length > 0 && socketConnection) {
      const homePageTimerStart = () => {
        let currentDateTime = moment(new Date()).format('x');
        let startTime =
          records && records.length > 0 ? records[0]['start_time'] : 0;
        let endTime =
          records && records.length > 0 ? records[0]['end_time'] : 0;
        let endTimeExpiration =
          records && records.length > 0 ? records[0]['end_time_expired'] : 0;
        let startTimeLocal = moment(startTime, 'x').format(
          'YYYY-MM-DD HH:mm:ss'
        );
        let endTimeLocal = moment(endTime, 'x').format('YYYY-MM-DD HH:mm:ss');
        let endTimeExpirationLocal = moment(endTimeExpiration, 'x').format(
          'YYYY-MM-DD HH:mm:ss'
        );
        let startTimeInterval;
        let endTimeInterval;
        let endTimeIntervalExpiration;
        if (currentDateTime > endTime) {
          setIsBidCompleted(true);
          if (
            currentDateTime > startTime &&
            currentDateTime < endTimeExpiration
          ) {
            endTimeExpiration = moment(endTimeExpirationLocal).format(
              'YYYY-MM-DD HH:mm:ss'
            );
            var countDownDate = new Date(endTimeExpiration).getTime();
            if (endTimeIntervalExpiration) {
              clearInterval(endTimeIntervalExpiration);
            }
            endTimeIntervalExpiration = setInterval(function () {
              var now = new Date().getTime();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
              let newDays = pad2(days);
              let newHours = pad2(hours);
              let newMinutes = pad2(minutes);
              let newSeconds = pad2(seconds);
              let timeString = `${newDays}:${newHours}:${newMinutes}:${newSeconds}`;
              let totalTime =
                parseInt(days) +
                parseInt(hours) +
                parseInt(minutes) +
                parseInt(seconds);
              if (parseInt(totalTime) === 0) {
                updateBidMasterStatusFunc();
              } else {
                setTimerString(timeString);
              }
            }, 1000);
            function pad2(d) {
              return d < 10 ? '0' + d.toString() : d.toString();
            }
          }
        } else {
          if (currentDateTime > startTime && currentDateTime < endTime) {
            endTime = moment(endTimeLocal).format('YYYY-MM-DD HH:mm:ss');
            var countDownDate = new Date(endTime).getTime();
            if (endTimeInterval) {
              clearInterval(endTimeInterval);
            }
            endTimeInterval = setInterval(function () {
              var now = new Date().getTime();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
              let newDays = pad2(days);
              let newHours = pad2(hours);
              let newMinutes = pad2(minutes);
              let newSeconds = pad2(seconds);
              let timeString = `${newDays}:${newHours}:${newMinutes}:${newSeconds}`;
              let totalTime =
                parseInt(days) +
                parseInt(hours) +
                parseInt(minutes) +
                parseInt(seconds);
              if (parseInt(totalTime) === 0) {
                updateBidMasterStatusFunc();
              } else {
                setTimerString(timeString);
              }
            }, 1000);
            function pad2(d) {
              return d < 10 ? '0' + d.toString() : d.toString();
            }
          }
          if (currentDateTime < startTime) {
            startTime = moment(startTimeLocal).format('YYYY-MM-DD HH:mm:ss');
            var countDownDate = new Date(startTime).getTime();
            if (startTimeInterval) {
              clearInterval(startTimeInterval);
            }
            startTimeInterval = setInterval(function () {
              var now = new Date().getTime();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
              let newDays = pad(days);
              let newHours = pad(hours);
              let newMinutes = pad(minutes);
              let newSeconds = pad(seconds);
              let timeString = `${newDays}:${newHours}:${newMinutes}:${newSeconds}`;
              let totalTime =
                parseInt(days) +
                parseInt(hours) +
                parseInt(minutes) +
                parseInt(seconds);
              if (parseInt(totalTime) === 0) {
                updateBidMasterStatusFunc();
              } else {
                setTimerString(timeString);
              }
            }, 1000);
            function pad(d) {
              return d < 10 ? '0' + d.toString() : d.toString();
            }
          }
        }
      };
      homePageTimerStart();
    }
  }, [records, socketConnection]);
  const getBidHistoryFunction = async (data) => {
    let postData = {
      bidMasterID: data.id,
    };
    const response = await bidHistory(postData);
    if (response.status === 200) {
      setHistoryData(response.data.data);
      setSelectHistoryModal(true);
    }
  };
  const selectNFT = async (data) => {
    setBidMasterID(data?.id);
    setBidData(data);
    let params = {
      ...selectFilter,
      userId: data?.userState?.userProfileData?.id,
      is_new: 1,
    };
    setSelectFilter(params);
    getNFTForSelectionFunc(params);
    setSelectedPosition(data.position)
    setSelectNFTModal(true);
  };
  const onSelectSearchChange = async (value) => {
    let params = {
      ...selectFilter,
      search: value,
      page_no: 0,
      is_new: 1,
    };
    setSelectFilter(params);
    getNFTForSelectionFunc(params);
  };
  const loadMoreSelectNFT = async (value) => {
    let params = {
      ...selectFilter,
      page_no: selectFilter.page_no + 1,
      is_new: 2,
    };
    setSelectFilter(params);
    getNFTForSelectionFunc(params);
  };
  const getNFTForSelectionFunc = async (params) => {
    const response = await getNFTForSelection(params);
    if (response.status === 200) {
      let currentData =
        params?.is_new === 1
          ? response.data.data.data
          : response.data.data.data.concat(nftSelectList);
      setNFTSelectList(currentData);
      setNFTisNext(response.data.data.is_next);
    }
  };
  const setNFTasSelected = async (data) => {
    Swal.fire({
      title: 'NFT Selection confirmation',
      // text: 'You wil not able to change NFT.',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#7557BB',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes Select',
    }).then(async (result) => {
      if (result?.isConfirmed) {
      let tmpObject = Object.assign([], selectedNftId);
      tmpObject[selectedPosition] = data?.image?.home_page_listings || '/assets/img/covers/cover1.jpg'
      setSelectedNFT(tmpObject)

      setSelectedNftId(data?.id)
      setSelectNFTModal(false);
      setSelectedPosition();
      }
    });
  };
  useEffect(() => {
    if (authState?.isAuthenticated) {
      getCurrentBidDataFunction();
    }
  }, [authState?.isAuthenticated, isBidCompleted]);
  const onPurchase = async (props) => {
    let requiredBlockChain =
      props.currency === 'MATIC' ? 'polygon' : 'ethereum';
    let isValidBlockChain = await SwitchNetwork(chainId, requiredBlockChain);
    if (isValidBlockChain) {
      showPurchaseNftPopup(props);
    }
  };
  const rediectToNFTDetails = async (data) => {
    router.push(data.linkURL);
  };
  const getAndUpdateUserData = async () => {
    const userResponse = await getProfile();
    if (userResponse.status === 200) {
      updateUserProfile(userResponse?.data?.data);
    }
  };
  const updateBidMasterStatusFunc = async () => {
    setShowCard(false);
    setTimeout(() => {
      props?.resetTimer();
    }, 15000);
  };
  const onChangeBidValue = (e, index) => {
    let tmpObject = Object.assign([], bidArr);
    tmpObject[index] = e.target.value;
    setBidArr(tmpObject);
  };
  
  return ( 
    <Section className={`${styles.top_rated_nfts}`}>
      <Container>
        {!showCard ? (
          <div className={styles.spotlight_section_loader}>
            <MainTitle title={'Sponsored Spotlight'} />
            <Row>
            {[1, 2, 3, 4].map((val, index) => {
              return <Col
                // key={index}
                className={`mt-3 mb-3 ${styles.col_card__clr}`}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                xxl={3}
              >
                <UserProfileLoader />
              </Col>
            })
            }
            </Row>
          </div>
        ) : (
          <>
            <div className={styles.spotlight_section}>
              <MainTitle title={'Sponsored Spotlight'} />
              <div className={styles.spotlight_separate}>
                <Button onClick={() => redirectBidPage()} varient={'primary'}>
                  Want to List My NFT Here
                </Button>
                <div className={styles.slider_btns}>
                  <span ref={leftArrow} className={styles.arrow_left}>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 13L1 7L7 1"
                        stroke="#666666"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span ref={rightArrow} className={styles.arrow_right}>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#666666"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <Swiper
              keyboard={true}
              mousewheel={false}
              spaceBetween={12}
              watchOverflow={true}
              touchStartPreventDefault={false}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 4,
                },
              }}
              autoplay={false}
              navigation={{
                prevEl: leftArrow.current,
                nextEl: rightArrow.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = leftArrow.current;
                swiper.params.navigation.nextEl = rightArrow.current;
              }}
              className="mySwiper"
            >
              {showCard &&
                records.map((data, index) => (
                  <SwiperSlide key={index}>
                    <SponsorSpotLightCard
                      isBidCompleted={isBidCompleted}
                      authState={authState}
                      userState={userState}
                      socketConnection={socketConnection}
                      timeString={timeString}
                      showConnectWalletPopup={showConnectWalletPopup}
                      lastPlacedBid={lastPlacedBid}
                      setLastPlacedBid={setLastPlacedBid}
                      lastPlacedBidNft={lastPlacedBidNft}
                      setLastPlacedBidNft={setLastPlacedBidNft}
                      getBidHistoryFunction={getBidHistoryFunction}
                      selectNFT={selectNFT}
                      gems={gems}
                      setGems={setGems}
                      onPurchase={onPurchase}
                      rediectToNFTDetails={rediectToNFTDetails}
                      {...data}
                      key={index}
                      getAndUpdateUserData={getAndUpdateUserData}
                      bid_page_view={2}
                      page_type={props?.page_type}
                      getCurrentBidDataFunction={getCurrentBidDataFunction}
                      setSelectNFTModal={setSelectNFTModal}
                      showCard={showCard}
                      onChangeBidValue={onChangeBidValue}
                      bidArr={bidArr}
                      setBidArr={setBidArr}
                      bidPage={false}
                      selectedNFT={selectedNFT}
                      selectedNftId={selectedNftId}
                      lastPlacedBidNftImg={lastPlacedBidNftImg}
                      lastPlacedBidId={lastPlacedBidId}
                      is_main_page={1}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </>
        )}
      </Container>
      <SelectNFTModal
        data={nftSelectList}
        isNext={nftIsNex}
        show={selectNFTModal}
        page_no={selectFilter.page_no}
        onHide={() => {
          setSelectNFTModal(false);
          setSelectFilter({
            page_no: 0,
            search: '',
            userId: null,
            is_new: 2,
          })
        }}
        onSelectSearchChange={onSelectSearchChange}
        loadMoreSelectNFT={loadMoreSelectNFT}
        setNFTasSelected={setNFTasSelected}
      />
      <ViewHistoryModal
        data={historyData}
        show={selectHistoryModal}
        onHide={() => {
          setSelectHistoryModal(false);
        }}
      />
    </Section>
  );
};

export default SponsorSpotLightSection;
