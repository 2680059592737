import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import SponsorSpotLightSection from '../Components/GEMSComponent/SponsorSpotLightSection';
import TopCollectionSection from '../Components/GEMSComponent/TopCollectionSection';
import MarketingPromoters from '../Containers/MarketingPromoter';
import UseAuth from '../Contexts/Auth/useAuth';
import useNFT from '../Contexts/NFT/useNFT';
import UseUser from '../Contexts/User/useUser';
import {
    getAllNFTGalleryLit,
    getAllNFTGiveAwayList,
    getAllNFTLit,
    getFeatureNFTList,
} from '../lib/NFT/index';
import {
    getCmsData,
    getMarketingPromoters,
    getReferralLink,
    getProfile,
} from '../lib/User';
import ReferalModal from '../Components/GEMSComponent/ReferalModal';
import { tr } from 'date-fns/locale';
import { getDeviceId } from '../Hooks/getDeviceId';
const Categories = dynamic(() => import('../Containers/Category'));
const Explore = dynamic(() => import('../Containers/Explore'));
const Hero = dynamic(() => import('../Containers/Hero'));
const Layout = dynamic(() => import('../Containers/Layout'));
const TopRated = dynamic(() => import('../Containers/TopRated'));
const TopGallery = dynamic(() => import('../Containers/TopGallery'));
const TopGiveaway = dynamic(() => import('../Containers/TopGiveaway'));
const Home = (props) => {
    const [MarketingPromoterData, setMarketingPromoterData] = useState([]);
    const [showSpotLight, setShowSpotlight] = useState(true);
    const {
        nftState,
        getExploreData,
        getToprated,
        getGalleryRecords,
        getGiveawayRecords,
    } = useNFT();
    const { SearchLisShowEventManagment, authState, SetSearchParameter } =
        UseAuth();
    const { userState, updateUserProfile } = UseUser();
    const getExploreDataList = async (data) => {
        getExploreData(data);
    };
    const getTopRatedNFTData = async (data) => {
        getToprated(data);
    };
    const getGalleryNFTData = async (data) => {
        getGalleryRecords(data);
    };
    const getGiveawayNFTData = async (data) => {
        getGiveawayRecords(data);
    };
    useEffect(() => {
        if (props.TopRatedData !== null) {
            getTopRatedNFTData(props.TopRatedData);
        }
        if (props.topGalleryNFTData !== null) {
            getGalleryNFTData(props.topGalleryNFTData);
        }
        if (props.exploreData !== null) {
            getExploreDataList(props.exploreData);
        }
        handleTopGiveawayData();
        // if (props.topGiveAwayNFTData !== null) {
        //   getGiveawayNFTData(props.topGiveAwayNFTData);
        // }
    }, [props]);
    const handleTopratedApicall = async () => {
        let ParamsTop = {
            key: 'top_rated',
            page_no: 0,
            page_size: 9,
            device_id: getDeviceId(),
        };
        let response = await getAllNFTLit(ParamsTop);
        if (response?.status === 200) {
            getToprated(response.data.data);
        }
    };
    const handleTopGiveawayData = async () => {
        let ParamsTop = {
            key: 'top_rated',
            page_no: 0,
            page_size: 9,
            device_id: getDeviceId(),
        };
        let response = await getAllNFTGiveAwayList(ParamsTop);
        if (response?.status === 200) {
            getGiveawayNFTData(response.data.data);
        }
    };
    const handleExploreApicall = async () => {
        let ParamsExplore = { page_no: 0, page_size: 9 };
        let responseExplore = await getAllNFTLit(ParamsExplore);
        if (responseExplore?.status === 200) {
            getExploreDataList(responseExplore.data);
        }
    };
    const getMarketingPromotersFunc = async () => {
        const response = await getMarketingPromoters();
        if (response.data.status === 1) {
            setMarketingPromoterData(response.data.data);
        }
    };
    useEffect(() => {
        getMarketingPromotersFunc();
    }, []);

    useEffect(() => {
        if (
            userState?.userProfileData?.public_address &&
            userState?.userProfileData?.public_address !== '' &&
            userState?.userProfileData?.is_referral_modal_load === 0
        ) {
            getReferralLinkFunc();
        }
    }, [userState]);
    const [refLink, setRefLink] = useState(null);
    const [referalModal, setReferalModal] = useState(false);
    const getReferralLinkFunc = async () => {
        let refLink = await getReferralLink();
        if (refLink.status === 200) {
            let link = refLink?.data?.data?.referral_link;
            if (link && link !== '') {
                setRefLink(link);
            }
        }
    };
    useEffect(() => {
        if (refLink && refLink !== '') {
            setReferalModal(true);
            updateUserProfile({
                ...userState?.userProfileData,
                is_referral_modal_load: 1,
            });
        }
    }, [refLink]);

    const getAndUpdateUserData = async () => {
        const userResponse = await getProfile();
        if (userResponse.status === 200) {
            updateUserProfile({
                ...userResponse?.data?.data,
                is_referral_modal_load: 1,
                gems: userResponse?.data?.data?.gems,
            });
        }
    };
    useEffect(() => {
        getAndUpdateUserData();
    }, []);

    const resetTimer = () => {
        setShowSpotlight(false);
        setTimeout(() => {
            setShowSpotlight(true);
        }, 100);
    };
    return (
        <div
            onClick={() => {
                if (authState?.showList === true) {
                    SearchLisShowEventManagment(false);
                    SetSearchParameter('');
                }
            }}
        >
            <Layout>
                <Hero content={props?.aboutUsData} />
                <Categories section="top_rated" />
                <TopRated
                    data={nftState.topRated}
                    propsTopRatedData={props.TopRatedData}
                />
                {showSpotLight && (
                    <SponsorSpotLightSection
                        resetTimer={resetTimer}
                        page_type={1}
                    />
                )}

                <TopGallery
                    data={nftState.topGalleryData}
                    propsTopGalleryData={props.topGalleryNFTData}
                />
                <TopGiveaway
                    data={nftState.topGiveawayData}
                    propsTopGiveawayData={props.topGiveAwayNFTData}
                />
                <Explore
                    data={nftState?.explore}
                    propsExploreData={props?.exploreData?.data}
                />

                <TopCollectionSection />

                <MarketingPromoters data={MarketingPromoterData} />
                <ReferalModal
                    refLink={refLink}
                    show={referalModal}
                    onHide={() => {
                        setReferalModal(false);
                    }}
                />
            </Layout>
        </div>
    );
};
export async function getServerSideProps(context) {
    let topGalleryNFTData = null;
    let TopRatedData = null;
    let exploreData = null;
    let topGiveAwayNFTData = null;
    let device_id = '';
    device_id = getDeviceId();

    let ParamsExplore = { page_no: 0, page_size: 12, device_id: device_id };
    let responseExplore = await getAllNFTLit(ParamsExplore);
    if (responseExplore?.status === 200) {
        let datalist = [];
        let addeddata = responseExplore?.data?.data[0];
        datalist.push(addeddata);
        exploreData = responseExplore.data;
    }
    let ParamsTop = {
        key: 'top_rated',
        page_no: 0,
        page_size: 12,
        device_id: device_id,
    };
    let response = await getFeatureNFTList(ParamsTop);
    if (response?.status === 200) {
        let data = [];
        let addeddata = response?.data?.data[0];
        data.push(addeddata);
        TopRatedData = response.data.data;
    }
    // Gallery
    let ParamsTopGallery = {
        key: 'top_rated',
        page_no: 0,
        page_size: 12,
        device_id: device_id,
    };
    let responseGallery = await getAllNFTGalleryLit(ParamsTopGallery);
    if (responseGallery?.status === 200) {
        let dataGallery = [];
        let addeddataGallery = responseGallery?.data?.data[0];
        dataGallery.push(addeddataGallery);
        topGalleryNFTData = responseGallery.data.data;
    }
    // Givaway
    let ParamsTopGiveaway = {
        key: 'top_rated',
        page_no: 0,
        page_size: 12,
        device_id: device_id,
    };
    let responseGiveaway = await getAllNFTGiveAwayList(ParamsTopGiveaway);
    if (responseGiveaway?.status === 200) {
        let dataGiveawy = [];
        let addeddataGiveawy = responseGiveaway?.data?.data[0];
        dataGiveawy.push(addeddataGiveawy);
        topGiveAwayNFTData = responseGiveaway.data.data;
    }
    let headercontent = null;
    let headerDatacall = await getCmsData('header');
    if (headerDatacall?.status === 200) {
        headercontent = headerDatacall.data.data;
    }
    return {
        props: {
            TopRatedData,
            topGalleryNFTData,
            topGiveAwayNFTData,
            exploreData,
            aboutUsData: headercontent,
        },
    };
}
export default Home;
