import Image from 'next/image';
import React from 'react';
import SVG from 'react-inlinesvg';
import Button from '../../../../Components/Button';
import styles from './styles.module.scss';
import NFT_TYPES from '../../../../Configs/NFTTypes';
const SelectNFTCard = (props) => {
  return (
    <>
      <div className={`${styles.selectnft_data_separates}`}>
        {props?.data.map((data, index) => {
          return (
            <>
              <div className={`${styles.selectnft_card}`}>
                <div className={`${styles.selectnft_img_separate}`}>
                  <Image
                    alt="Image"
                    src={data?.image?.home_page_listings || '/assets/img/covers/cover1.jpg'}
                    height="336px"
                    width="340px"
                    className={`${styles.selectnft_img}`}
                  />
                  <div className={`${styles.selectnft_gems_small_img}`}>
                    <img
                      src={
                        data?.category
                          ? NFT_TYPES[data?.category.toUpperCase()]?.icon
                          : ''
                      }
                      width="23px"
                      height="23px"
                      className={`${styles.catagorey}`}
                      alt={data?.category}
                    />
                  </div>
                </div>
                <div className={`${styles.selectnft_separate}`}>
                  <h2 className={`${styles.selectnft_username}`}>
                    {data?.title}
                  </h2>
                </div>
                <div className={`${styles.selectnft_btnhover}`}>
                  <Button
                    varient={'primary'}
                    className={`${styles.nobtn_border}`}
                    onClick={()=>props?.setNFTasSelected(data)}
                  >
                    Select NFT
                  </Button>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SelectNFTCard;
