import React from 'react';
import { Modal } from 'react-bootstrap';
import HistoryTable from './HistoryTable';
import styles from './styles.module.scss';
const ViewHistoryModal = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    className={`${styles.nomodalborder}`}
                ></Modal.Header>
                <Modal.Body>
                    <div className={`${styles.selectnft_box}`}>
                        <div className={`${styles.selectnft_heading_separate}`}>
                            <h2 className={`${styles.selectnft_title}`}>
                                Bid History
                            </h2>
                        </div>
                        <HistoryTable data={props?.data} />
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${styles.nomodalborder}`}>
                    {/* &nbsp; */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewHistoryModal;
